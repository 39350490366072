<!-- 供应商管理-> 就餐请假 -> 请假汇总 -->
<template>
    <div class="content-main" style="margin-top: 0px;">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" style="margin-top: 0">
                <expand-filter :formData="formData" @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType"
                    :closeWidth="1250"  @changeCascader="changeCascader"  @changeSel="changeSel" marginBottom="10px"
                    borderRadius="0px 4px 4px 4px" class="expand-filter-wrapper"
                ></expand-filter>
                <table-data :config="table_config" :tableData="table_data" v-loading="loadingTable" :density="tableDensity"
                    backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="rowStyle" ref="table"
                    id="table" :to-top="false">
                </table-data>
                <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                    @pagination="getList()" />
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    Loading,
    Pagination,
    DialogWrapper
} from "common-local";
	import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue'
import TableData from "@/components/scrollWrapper/Sub/TableData";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import { mapMutations,mapState } from "vuex";
import { downloadFile , formatTreeData } from "@/libs/utils";
import RecordsList from '@/libs/pageListOptimal.js';
import exportReactive from '@/libs/exportReactive.js';

export default {
    name: "StuRepastLeaveAll",
    components: {
        Loading,
        TableData,
        DialogWrapper,
        Pagination,
        ExpandFilter,
        TabbedPage,
    },
    data() {
        return {
            currentId: "first",
            tabList: [
                {
                    id: "first",
                    title: "请假明细",
                    sel: true
                },
                {
                    id: "second",
                    title: "请假汇总",
                    sel: false,
                }
            ],
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            tableDensity: '1',
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                projectId: '',  
                schoolId: '',  
                classIdList: [],
                studentName: '',
                startTime: "",
                endTime: "",
                projectName: "",
                schoolName: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "项目名称",
                        key: "projectId",
                        filterable: true,
                        list: []
                    },
                    {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "classIdList",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        }
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "学生姓名",
                        key: "studentName",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ['repastLeave:summary:list']
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "enquiry",
                        text: "导出",
                        fn: "export",
                        loading: false,
                        auth: ['repastLeave:summary:export']
                    },
                ]
            },
            table_config: {
                thead: [
                    {
                        label: "项目名称",
                        prop: "projectName",
                        align: "left",
                        labelWidth: "160px",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                    },
                    {
                        label: "姓名",
                        prop: "studentName",
                        align: "center",
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: "center",
                    },
                    
                    {
                        label: "商户订单号",
                        prop: "outTradeNo",
                        align: "center",
                        labelWidth: "200px",
                    },
                    {
                        label: "支付宝流水号",
                        prop: "bankNo",
                        align: "center",
                        labelWidth: "200px",
                    },
                    {
                        label: "请假天数",
                        prop: "leaveDays",
                        align: "center",
                    },
                ],
                check: false,
                height: ""
            },
            table_data: [],
            total: 0,
            rowStyle: {
                height: '48px'
            },
            titleOptions: [],  // 学校列表
        }
    },
    async created() {
        await this.init()
        this.setDefaultTitle();
        this.getOrgList()
        this.getList(1)
    },
    async activated() {
        await this.init()
        this.setDefaultTitle();
        this.getOrgList()
        this.getList(1)
    },
    mounted() {
        this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 135
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            schoolId: (state) => state.schoolId,
        }),
    },
    methods: {
        setDefaultTitle() {
            console.log('默认项目名称');
            
            this.formData.data.forEach((item) => {
                if (item.key == 'projectId') {
                    item.value = this.titleOptions[0]?.value;
                    if (item.value) {
                        this.listQuery[item.key] = this.titleOptions[0]?.value
                    } else {
                        this.listQuery[item.key] = ''
                    }
                }
            });
        },
        /**
         * @Description: 初始化数据
         * @Author: 闫乔
         * @Date: 2024-11-06 16:40:38
         */        
         async init() {
            this.listQuery.schoolId = this.schoolId;
            await this.getSchoolRepastList();  // 项目列表
        },
        
         /**
         * @Description: 获取项目列表
         * @Author: 闫乔
         * @Date: 2025-02-28 14:34:22
         */        
         async getSchoolRepastList() {
            await this._fet("/school/schoolRepast/listByCondition", { schoolId: this.schoolId }).then((res) => {
                this.handleRes(res, () => {
                    console.log('res.data.data',res.data.data);
                    const index = this.formData.data.findIndex((i) => i.key === 'projectId')
                    this.titleOptions = res.data.data.map(i => ({ label: i.title, value: i.id }));
                    console.log('项目列表',this.titleOptions);
                    this.formData.data[index].list = this.titleOptions;
                    this.formData.data[index].value = this.titleOptions[0]?.value
                    this.listQuery.projectId = this.titleOptions[0]?.value
                    
                });
            });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 选择学校获取班级列表
         * @Author: 闫乔
         * @Date: 2025-02-28 15:44:06
         * @param {*} data
         */        
        changeSel(data) {
        },
        /**
         * @Description: 展开收起
         * @Author: 闫乔
         * @Date: 2024-11-06 16:40:04
         */        
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
            })

        },
        /**
         * @Description: 查询/重置
         * @Author: 闫乔
         * @Date: 2024-11-06 16:40:19
         * @param {*} type
         */        
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                        if (item.key == "classIdList") {
                            if (!item.value) {
                                this.listQuery[item.key] = [];
                            }
                        }
                    });
                    this.getList(1);
                    break;
                case 'reset':
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = ''
                        item.value = ''
                    })
                    this.listQuery.classIdList = []
                    this.listQuery.projectId = this.titleOptions[0]?.value

                    this.setDefaultTitle();
                    this.getList(1)
                    break
                case "export":
                    exportReactive(this.formData.btnList, 'export', true)
                    this.handleExport()
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 导出
         * @Author: 闫乔
         * @Date: 2024-11-06 16:37:27
         */        
        handleExport() {
            let index = this.formData.btnList.findIndex((i) => i.fn === 'export')
            this.formData.btnList[index].loading = true
            downloadFile(
                {
                    url: "/school/schoolRepastLeave/exportLeaveSummary",
                    method: "post",
                    form: this.listQuery,
                },
                () => {
                    this.formData.btnList[index].loading = false
                    exportReactive(this.formData.btnList, 'export', false)
                    this.$message.success('导出成功')
                },
                () => {
                    this.formData.btnList[index].loading = false
                },
            );
        },
        /**
         * @Description: 获取表格数据
         * @Author: 闫乔
         * @Date: 2024-11-06 16:37:48
         * @param {*} isPaging
         */        
        getList(isPaging) {
            let data = {}
            if (isPaging) {
                this.listQuery.pageNum = 1
            }
            this.formData.data.forEach(item => {
                data[item.key] = item.value
            })
            this.loadingTable = true
            
            this._fet("/school/schoolRepastLeave/listLeaveSummary", this.listQuery).then(res => {
                if (res.data.code === '200') {
                    this.total = res.data.data.totalCount || 0
                    this.table_data = res.data.data.list || [];
                    
                    //赋值--存储滚动条高度
                    this.$nextTick(() => {
                        let recordsList =  new RecordsList()
                        if(recordsList.get(this.$route.name)){
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        //移除--存储滚动条高度
                        recordsList.remove(this.$route.name)
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        /**
         * @Description: 获取组织架构班级接口
         * @Author: 闫乔
         * @Date: 2024-11-06 16:38:36
         */       
         getOrgList() {
            this._fet("/school/schoolOrgan/listByConditionNoAuth", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    const list = res.data.data.list;
                    const list2 = list.filter((it) => it.organType != 1);
                    console.log('list2list2',list2);
                    
                    this.formData.data[index].list =
                        formatTreeData(list2, "id", "parentOrg")
                    
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        /**
         * @Description: 选中年级班级
         * @Author: 闫乔
         * @Date: 2024-11-06 16:38:55
         * @param {*} data
         */        
        changeCascader(data) {
            let node;
            if (data.ref.getCheckedNodes()[0]) {
                node = data.ref.getCheckedNodes()[0].data;
            } else {
            }
            data.ref.dropDownVisible = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.expand-filter-wrapper {
    border-bottom: 1px solid #F2F4F7;
    ::v-deep {
        .el-range-editor .el-range-input {
            background: #f5f5f5;
        }
        .el-input__inner:hover  {
            border: #d4d6d9 1px solid;
        }
        .el-range-editor.is-active  {
            border: #3c7fff 1px solid !important;
            background-color: #fff;
            .el-range-input {
                background-color: #fff;
            }
        }
    }
}
.status{
    display: flex;
    background-color: pink;
    .round{
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: red;
        margin-right: 5px;
    }
}
.round{
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: red;
        margin-right: 5px;
    }
.table-setting {
    ::v-deep .el-drawer__header {
        display: none;
    }
}
.img_box {
    width: 48px;
    height: 36px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
}
.info_btn {
    color: #878787;
    cursor: pointer;
}
.sucess_btn {
    color: #3C7FFF;
    cursor: pointer;
}
.del-off-dialog-box {
    .tip_title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 18px;
        color: #2B2F33;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .tip_content {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #6D6F73;
        line-height: 24px;
    }
}
.total-class-box {
    background-color: #fff;
    height: 32px;
    line-height: 32px;
    padding: 10px 20px 0;
    font-size: 14px;
}
</style>
